.img {
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 480px;
}
/* * {
  font-family: "Exo 2", sans-serif;
  font-size: large;
  line-height: 1.5;
} */
/* .sidebarcontent.open {
      width: 210px;
      background-color: aliceblue;
      height: 100vh;
      position: fixed;
      top: 0;
      z-index: 2;
    } */
.back-btn-color {
  background-color: white !important;
  color: gray !important;
  border-color: gray !important;
}
.back-btn-color:hover {
  background-color: gray !important;
  color: white !important;
  transform: scale(1.02);
}
@media only Screen and (min-width: 768px) {
  .button-Style {
    padding: 7px 25px !important;
  }
}

.save-button {
  display: flex;
  justify-content: flex-end;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.save-btn-color {
  background-color: #dfab2d !important;
  border-color: #dfab2d !important;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.save-btn-color:hover {
  background-color: #dfab2d !important;
  transform: scale(1.02);
}
.next-btn-color {
  background-color: #32527b !important;
  border-color: #32527b !important;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.next-btn-color:hover {
  background-color: #204a7e !important;
  transform: scale(1.02);
}
@media only Screen and (max-width: 362px) {
  .next-btn-color {
    margin-top: 8px !important;
  }
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #0d6efd;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  color: #fff;
}
.h3-color {
  color: #37517e;
}
.headline {
  color: #4e6b9d;
}
.mission {
  /* Created with https://www.css-gradient.com */
  background: #23ec55;
  background: -webkit-radial-gradient(top right, #23ec55, #2d51c1);
  background: -moz-radial-gradient(top right, #23ec55, #2d51c1);
  background: radial-gradient(to bottom left, #23ec55, #2d51c1);
}
.card-border {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background-color: #4e6b9d;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
}
.content {
  padding: 6% !important;
}
.mission-font {
  font-size: 16px;
}
.contents {
  padding: 6% 15% 27% 15% !important;
}
.card-borders {
  border-radius: 10% 10% 10% 10% / 10% 10% 10% 10%;
  background-color: #4e6b9d;
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  color: #fff;
}
.carousel-control-prev {
  left: -56px !important;
}
.carousel-control-next {
  right: -54px !important;
}
.quality-text {
  font-size: 14px;
  padding-bottom: 2% !important;
}
.dedictaion-text {
  padding-bottom: 4% !important;
  font-size: 14px;
}
.contentss {
  padding: 6% 15% 18% 15% !important;
}
.integrity-size {
  font-size: 16px;
  padding-bottom: 7%;
}
.required:after {
  content: " *";
  color: red;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 18px;
  height: 18px;
  margin: 3px 9px;
  border-radius: 50%;
  background-color: #035e8e;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.border-shape {
  border-top-left-radius: 3%;
  border-top-right-radius: 3%;
  border-bottom-left-radius: 1%;
  border-bottom-right-radius: 1%;
}
.shadow-remove {
  scrollbar-shadow-color: #23ec55;
}

.navbar-height{
  height: 5px;
}